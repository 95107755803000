export default {
  'clinical.blockServiceWithoutAccount': 'Bloquear atendimento médico sem uma conta registrada',
  'clinical.allowEditFinishedAppointment': 'Permitir editar agendamentos finalizados',
  'sphere.allowInvalidExpenses': 'Permitir agendamento de despesas não cadastradas',
  'sphere.allowSlotting': 'Permitir agendamento por encaixe',
  'pacs.enabled': 'Habilitar serviço de PACS',
  'conectadoc.outcome': '[ConectaDoc] Habilitar tela de desfecho no prontuário',
  'attendance.medicalReport': '[Central de Laudos] Listar apenas os exames do profissional',
  'relationship.birthday': 'Enviar mensagem para aniversariantes',
  'relationship.schedulingPerformed': 'Enviar mensagem ao realizar um agendamento',
  'relationship.appointmentConfirmation': 'Enviar mensagem de confirmação de agendamento',
  'relationship.appointmentReminder': 'Enviar mensagem de lembrete de agendamento',
  'relationship.satisfactionSurvey': 'Envio de pesquisa de satisfação logo após o atendimento',
};
