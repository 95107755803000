import moment from 'moment';
import { deepClone } from '@/helpers/object';

export const appointmentModalTitle = state => (
  `Agendamento - ${moment(state.form.startDate.substring(0, 10))
    .format('dddd, D [de] MMMM [de] YYYY')}`
);

export const getProfessionalById = state => (
  (id, specialties = null) => {
    let professional = state.professionals.find(item => item.id === id);
    if (professional && specialties !== null) {
      professional = deepClone(professional);
      professional.specialties = professional.specialties
        .filter(({ code }) => specialties.includes(code));
    }
    return professional;
  }
);

export const getInsuranceByPlanId = state => (
  id => state.insurances.find(({ plan }) => plan.id === id)
);

export const getScheduleById = state => (
  id => state.schedules.find(item => item.id === id)
);

export const hasClipboardData = state => (
  !!state.clipboard.event && !!state.clipboard.data
);
