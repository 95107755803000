import { http } from '@/plugins/http';

const defaultParams = {
  toCalendar: true,
};

export async function create(data) {
  const response = await http.post('/appointments', data, {
    params: defaultParams,
  });
  return response.data;
}

export async function update(id, data) {
  const response = await http.put(`/appointments/${id}`, data, {
    params: defaultParams,
  });
  return response.data;
}

export async function remove(id) {
  const response = await http.delete(`/appointments/${id}`);
  return response.data;
}

export async function setInUse(key) {
  const response = await http.post(`/appointments/${key}/in-use`);
  return response.data;
}

export async function unsetInUse(key) {
  const response = await http.delete(`/appointments/${key}/in-use`);
  return response.data;
}
