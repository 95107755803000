export const councils = {
  ABQ: 'Associação Brasileira de Quiropraxia',
  CBOO: 'Conselho Brasileiro de Óptica e Optometria',
  CRAS: 'Conselho Regional de Assistência Social',
  CRESS: 'Conselho Regional de Serviço Social',
  COREN: 'Conselho Regional de Enfermagem',
  CRF: 'Conselho Regional de Farmácia',
  CREFONO: 'Conselho Regional de Fonoaudiologia (CREFONO)',
  CRFA: 'Conselho Regional de Fonoaudiologia (CRFA)',
  CREFITO: 'Conselho Regional de Fisioterapia e Terapia Ocupacional',
  CRM: 'Conselho Regional de Medicina',
  CRN: 'Conselho Regional de Nutrição',
  CRO: 'Conselho Regional de Odontologia',
  CRP: 'Conselho Regional de Psicologia',
  CRBio: 'Conselho Regional de Biologia',
  CRBM: 'Conselho Regional de Biomedicina',
  CREF: 'Conselho Regional de Educação Física',
  CRMV: 'Conselho Regional de Medicina Veterinária',
  CRTR: 'Conselho Regional de Técnicos em Radiologia',
  RMS: 'Registro do Ministério da Saúde',
};

export function formatCouncil(value) {
  if (!value || !('record' in value)) {
    return '';
  }
  return `${value.name}-${value.state} ${value.record}`;
}
